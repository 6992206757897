import { yupResolver } from '@hookform/resolvers/yup';
import Ancord from 'components/Ancord';
import Button from 'components/Button';
import Content from 'components/Content';
import Divider from 'components/Divider';
import FormTemporaryCode from 'components/Forms/FormTemporaryCode';
import Input from 'components/Input';
import Title from 'components/Title';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { SignUpSchema } from 'schemas/signup';
import { getToken, signup } from 'services/auth';
import { authState } from 'state/auth';

import MindoveLogoWhite from '../../assets/icons/logotipo_white_transparent.png';

export default function Signup() {
  // Global state
  const [, setAuth] = useRecoilState(authState);

  // Local state
  const [email, setEmail] = useState(null);

  // React Router
  const navigate = useNavigate();

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError: setSignupError,
  } = useForm({
    resolver: yupResolver(SignUpSchema),
  });
  const {
    register: codeRegister,
    handleSubmit: handleCodeSubmit,
    setError: setTemporaryCodeError,
    formState: { errors: temporaryCodeErrors },
  } = useForm({});

  // Response actions
  const onErrorSignup = ({ response: { data } }) => {
    const message = data?.email[0] || data?.error;
    setSignupError('email', { message: message });
  };

  const onSuccessGetToken = (data) => {
    setAuth({ token: data.data.token, user: data.data.user });
    navigate('/app');
  };
  const onErrorGetToken = (data) => {
    const errors = data.response?.data;
    const message = errors?.code || errors?.error;
    setTemporaryCodeError('digit_0', { message });
  };

  // React Query
  const signupMutation = useMutation(
    ({ email, firstName, lastName }) => signup(email, firstName, lastName),
    {
      onError: onErrorSignup,
    },
  );
  const tokenMutation = useMutation((data) => getToken(data.code, data.email), {
    onSuccess: onSuccessGetToken,
    onError: onErrorGetToken,
  });

  const onSubmit = (data) => {
    setEmail(data.email);
    signupMutation.mutate({
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
    });
  };

  const onSubmitCode = (data) => {
    const code = Object.values(data).join('');
    tokenMutation.mutate({ code, email });
  };

  return (
    <div className="lg:h-full grid grid-cols-1 lg:grid-cols-2 items-center gradient-primary">
      <div className="md:py-12 px-6 md:px-4 lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-md md:max-w-md lg:w-96">
          <div className="px-4 md:px-0 py-4 md:py-0 box-border my-4">
            {/* mobile */}
            <div className="">
              <div>
                <img alt="Mindove" src={MindoveLogoWhite} />
              </div>
              <div className="my-4">
                <Title className="text-white">Regístrate</Title>
              </div>
              <div className="block lg:hidden w-full">
                <iframe
                  className="w-full h-52"
                  src="https://www.youtube.com/embed/pqhiysoeXfg?si=2okw8en-q2gSXln-"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>

          <div className="px-4 mb-4 md:px-0  rounded-t-2xl">
            <div>
              {signupMutation.isSuccess ? (
                <>
                  <FormTemporaryCode
                    handleSubmit={handleCodeSubmit}
                    onSubmit={onSubmitCode}
                    register={codeRegister}
                    mutation={tokenMutation}
                    error={temporaryCodeErrors?.digit_0?.message}
                  />
                  <Content className="mt-4 font-light text-white">
                    Consulta tu Email{' '}
                    <span className="font-bold text-xl text-tertiary">{email}</span>
                    <br /> Recibirás un código de acceso, utilizalo para acceder a tu cuenta.
                  </Content>
                </>
              ) : (
                <>
                  <form onSubmit={handleSubmit(onSubmit)} method="POST" className="space-y-6">
                    <Input
                      id="email"
                      type="email"
                      register={register}
                      registerConfig={{ required: true }}
                      labelClasses="text-white"
                      withLabel
                      textLabel="Email"
                      error={errors.email?.message}
                    />
                    <Input
                      id="firstName"
                      type="text"
                      register={register}
                      registerConfig={{ required: true }}
                      labelClasses="text-white"
                      withLabel
                      textLabel="Nombre"
                      error={errors.firstName?.message}
                    />
                    <Input
                      id="lastName"
                      type="text"
                      register={register}
                      registerConfig={{ required: true }}
                      labelClasses="text-white"
                      withLabel
                      textLabel="Apellidos"
                      error={errors.lastName?.message}
                    />
                    <div className="">
                      <Button type="submit" className="btn-tertiary">
                        Crear mi Usuario
                      </Button>
                    </div>
                  </form>

                  <br />
                  <div>
                    <Divider />
                  </div>
                  <br />
                  <div>
                    <Title className="font-bold text-white">¿Ya estás registrado?</Title>
                    <Content className="mt-2 text-white mb-10 md:mb-0">
                      Accede y comienza tu proceso de terapia psicoespiritual{' '}
                      <Ancord href="/login" color="font-bold text-xl text-tertiary">
                        Iniciar sesión
                      </Ancord>
                    </Content>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="hidden lg:block  md:py-12 px-6 md:px-4 lg:px-20 xl:px-24">
        {/* <img className="absolute inset-0 h-full w-full object-cover" src={BackgroundImage} alt="" /> */}
        <div className="">
          <div className=" mt-12">
            <Title className="text-white mb-6">
              Acompañamiento Terapéutico Online con Psicólogos Católicos Certificados
            </Title>
            {/* <div className="w-full">
              <iframe
                className="w-full h-72"
                src="https://www.youtube.com/embed/Ne5E72MkrLc"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div> */}

            <br />
            <Content className="text-white font-extralight">
              La Salud Mental es primordial en tu vida y por ello no se la puedes entregar a
              cualquiera.
            </Content>
            <br />
            <Content className="text-white">Consultas desde $59 USD</Content>
            <br />
            <Content className="text-white font-extralight">
              Con Mindove puedes confiar en nuestros psicólogos especializados, quienes te atenderán
              desde la Psicología Humanista y el{' '}
              {/* <a
                className="font-medium text-primary"
                href="https://mindove.org/acerca-de-mindove-psychology/#principios"
              >
                Sistema de Creencias Cristianas.
              </a> */}
              <span className="font-bold text-xl text-white">Sistema de Creencias Cristianas.</span>
            </Content>
            <br />
            <Content className="text-white font-extralight ">
              Recupera tu salud mental entre aquellos en lo que más confías.
            </Content>
          </div>
        </div>
      </div>
    </div>
  );
}
